import React from "react";
import "./Newsletter.scss";

import { Link } from "react-router-dom";

import July from "../../assets/newsletters/MPHCA July e-Newsletter.pdf";
import August from "../../assets/newsletters/MPHCA August e-Newsletter.pdf";

function NewsLetter() {
  return (
    <div className="newsletter">
      <a href={July}>
        <button className="newsletter__button">July Newsletter</button>
      </a>
      <a href={August}>
        <button className="newsletter__button">August Newsletter</button>
      </a>

      <Link to="/anti-racism">
        <button className="newsletter__button">Anti Racism Charter</button>
      </Link>
    </div>
  );
}

export default NewsLetter;
