import React from "react";
import "./Hero.scss";
import { useState } from "react";
import left from "../../assets/icons/left.svg";
import right from "../../assets/icons/right.svg";

const images = [
  {
    image: "images/hero-images/1.jpg",
  },
  {
    image: "images/hero-images/2.jpg",
  },
  {
    image: "images/hero-images/3.jpg",
  },
  {
    image: "images/hero-images/4.jpg",
  },
  {
    image: "images/hero-images/5.jpg",
  },
];

function Hero() {
  const [active, setActive] = useState(0);
  const length = images.length;

  const prevImage = () => {
    setActive(active === 0 ? length - 1 : active - 1);
  };

  const nextImage = () => {
    setActive(active === length - 1 ? 0 : active + 1);
  };

  // console.log(active);

  return (
    <div className="hero" id="hero">
      <img
        onClick={prevImage}
        className="hero__left"
        src={left}
        alt="next image arrow"
      ></img>
      <img
        onClick={nextImage}
        className="hero__right"
        src={right}
        alt="previous image arrow"
      ></img>
      <div className="hero__overlay"></div>
      {images.map((image, index) => {
        return (
          <div className={index === active ? "hero__image" : null} key={index}>
            {index === active && (
              <img
                src={image.image}
                alt="plumbing image"
                className="hero__image"
              />
            )}
          </div>
        );
      })}
      <div className="hero__mobile--section">
        <h1 className="hero__header">
          The Metropolitan Plumbing and Heating Contractors Association
        </h1>
        <h4 className="hero__subheader">
          is the voice of residential high-rise mechanical plumbing and heating
          industry in the Greater Toronto Area
        </h4>
        {/* <button className="hero__learn">LEARN MORE</button> */}
      </div>
      <div className="hero__tablet--section">
        <h1 className="hero__header">
          The Metropolitan Plumbing and Heating Contractors Association
        </h1>
        <h4 className="hero__subheader">
          is the voice of residential high-rise mechanical plumbing and heating
          industry in the Greater Toronto Area
        </h4>
        <div className="hero__buttons">
          {/* <button className="hero__join">JOIN</button> */}
          {/* <button className="hero__learn hero__learn--tablet">
            LEARN MORE
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Hero;
