import "./App.css";
import Nav from "../src/components/Nav/Nav";
import Footer from "../src/components/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AntiRacism from "./pages/AntiRacism/AntiRacism";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="anti-racism" element={<AntiRacism />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
