import React, { useState } from "react";
import "./NavList.scss";
import { Link, useNavigate } from "react-router-dom";
function NavList() {
  let navigate = useNavigate();

  const navigateTo = async (path) => {
    const myPromise = new Promise((resolve, reject) => {
      resolve(navigate(`/#${path}`));
    });
    myPromise.then(() => {
      setTimeout(() => {
        document
          .getElementById(path)
          .scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
      }, 100);
    });
  };

  return (
    <>
      <ul className="nav-list">
        <li className="nav-list__item">
          <a
            className="nav-list__link"
            onClick={() => {
              navigateTo("hero");
            }}
          >
            Home
          </a>
        </li>
        <li className="nav-list__item">
          <a
            className="nav-list__link"
            onClick={() => {
              navigateTo("members");
            }}
          >
            Members
          </a>
        </li>
        <li className="nav-list__item">
          <a
            className="nav-list__link"
            onClick={() => {
              navigateTo("board");
            }}
          >
            Board & Directors
          </a>
        </li>

        <li className="nav-list__item">
          <a
            className="nav-list__link"
            onClick={() => {
              navigateTo("contact-form");
            }}
          >
            Contact Us
          </a>
        </li>
      </ul>
    </>
  );
}

export default NavList;
