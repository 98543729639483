import React from "react";
import Newsletter from "../Newsletter/Newsletter";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <Newsletter />
      <div className="footer__section">
        <p className="footer__text">70 Leek Crescent</p>
        <p className="footer__text">Richmond Hill, Ontario L4B 1H1</p>
        <p className="footer__text">Phone: (416)-499-4000</p>
        <p className="footer__text">Fax: (416) 499-8752</p>
      </div>
      <div className="footer__section">
        <p className="footer__copyright">Copyright Metropolitan Plumbing & Heating Contractors Association ©</p>
      </div>
    </div>
  );
}

export default Footer;
