import Board from "../../components/Board/Board";
import Contact from "../../components/Contact/Contact";
import Hero from "../../components/Hero/Hero";
import Members from "../../components/Members/Members";
import "./HomePage.scss";

function HomePage() {
  return (
    <>
      <Hero />
      <Members />
      <Board />
      <Contact />
    </>
  );
}

export default HomePage;
