import React from "react";
import "./Board.scss";
import board from "../../board";

function Board() {
  return (
    <div className="board" id="board">
      <h2 className="board__header">Our Board of Directors</h2>
      <div className="board__members">
        {board &&
          board.map((board) => {
            return (
              <div className="board__card" key={board.id}>
                <div className="board__box"></div>
                <img
                  className="board__image"
                  src={board.image}
                  alt="board member portrait"
                ></img>
                <h3 className="board__name">{board.name}</h3>
                <h4 className="board__title">{board.title}</h4>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Board;
