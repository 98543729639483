import React from "react";
import "./Contact.scss";

import emailjs from "emailjs-com";
import { useRef, useState } from "react";
import { useEffect } from "react";

function Contact() {
  const [fromName, setFromName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const [fromNameError, setFromNameError] = useState(false);
  const [fromEmailError, setFromEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [formError, setFormError] = useState(false);

  const sendForm = async (e) => {
    e.preventDefault(e);

    if (fromName.length <= 2) {
      setFromNameError(true);
      return;
    }

    if (company.length <= 2) {
      setCompanyError(true);
      return;
    }

    if (fromEmail.length <= 3 || !fromEmail.includes("@")) {
      setFromEmailError(true);
      return;
    }

    if (message.length <= 15) {
      setMessageError(true);
      return;
    }

    if (message.length <= 15 || fromEmail.length <= 3 || fromName.length <= 3) {
      setFormError(true);
      return;
    } else {
      var templateParams = {
        from_name: fromName,
        user_email: fromEmail,
        company: company,
        message: message,
      };
      await emailjs
        .send(
          "service_kgu70sm",
          "contact_form",
          templateParams,
          "QdeLXIZHaJh4tGVTM"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        ).then;
    }
    alert("Email successfully sent");
    setFromName("");
    setFromEmail("");
    setCompany("");
    setMessage("");
  };

  // const clearForm = () => {
  //   setFromName("");
  //   setFromEmail("");
  //   setCompany("");
  //   setMessage("");
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     clearForm();
  //   }, "2000");
  // }, [sendForm]);

  return (
    <form className="contact" id="contact-form" onSubmit={sendForm}>
      <h2 className="contact__title">Send us a message!</h2>
      <label className="contact__label">Enter your name</label>
      {fromNameError ? (
        <label className="contact__label contact__label--error">
          Name must be at least 3 characters
        </label>
      ) : null}
      <input
        className="contact__input"
        type="name"
        name="from_name"
        onChange={(e) => setFromName(e.target.value)}
        value={fromName}
      ></input>
      <label className="contact__label">
        Enter your company/organization name
      </label>
      {companyError ? (
        <label className="contact__label contact__label--error">
          Please enter valid company name.
        </label>
      ) : null}
      <input
        className="contact__input"
        type="text"
        name="company"
        onChange={(e) => setCompany(e.target.value)}
        value={company}
      ></input>
      <label className="contact__label">Enter your email</label>
      {fromEmailError ? (
        <label className="contact__label contact__label--error">
          Please enter valid email
        </label>
      ) : null}
      <input
        className="contact__input"
        // type="email"
        name="user_email"
        onChange={(e) => setFromEmail(e.target.value)}
        value={fromEmail}
      ></input>
      <label className="contact__label">Enter your message</label>
      {messageError ? (
        <label className="contact__label contact__label--error">
          Message must be at least 15 characters long
        </label>
      ) : null}
      <textarea
        className="contact__input contact__input--textarea"
        for="email_body"
        type="message"
        name="message"
        onChange={(e) => setMessage(e.target.value)}
        value={message}
      ></textarea>
      {formError ? (
        <label className="contact__label contact__label--error">
          Please fill out all fields
        </label>
      ) : null}
      <button className="contact__button" type="submit">
        SEND
      </button>
    </form>
  );
}

export default Contact;
