import { useEffect } from "react";
import "./AntiRacism.scss";

function AntiRacism() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="anti">
      <h2 className="anti__title">A Charter for Anti-Racism for The Metropolitan Plumbing and Heating Contractors Association (MPHCA)</h2>
      <p className="anti__text">
        As an industry that relies on the skills and dedication of people from all different backgrounds, the recent protests against violence and
        systemic racism, both in Canada and abroad, made it clear to us that we needed to be more visible and vocal about our belief that racism, hate
        and bigotry have no place in the work we do or in the communities that we serve and live in.
      </p>
      <p className="anti__text">
        For that reason, in support of our members, we developed a Charter for Anti-Racism. Adopting the Charter means committing to being an active
        part of achieving the five objectives that are outlined and that we believe will help us all recognize, respect and prioritize diversity,
        inclusion and anti-racist behavior.
      </p>
      <p className="anti__text">
        As the voice of the residential high-rise mechanical plumbing and heating industry, representing more than 2,200 skilled tradespeople in the
        GTA, we firmly believe that racism, hate and bigotry have no place in our industry. On the contrary, we recognize that diversity and inclusion
        make us stronger and so we are committed to doing our part as an organization to combat systemic and individual racism.
      </p>
      <p className="anti__text">
        To embody our commitment, we have created a Charter for Anti-Racism in support of our members that outlines five core objectives:
      </p>

      <ol className="anti__list">
        <li className="anti__text">Increase awareness of, and appreciation for, the diversity of race, colour, and culture within our industry.</li>
        <li className="anti__text">
          Create working environments that are free of racism and where people have the assurance they will be treated with dignity and respect.
        </li>
        <li className="anti__text">
          Work with government, unions, and regulatory bodies across the building and construction trades to develop policies, programs and
          initiatives to reduce and eliminate racism, hate and bigotry, and creating opportunities for all individuals, including BIPOC and
          organizations to thrive.
        </li>
        <li className="anti__text">
          Ensure that the policies, procedures and practices are reviewed or developed to reflect the principle of equity for all.
        </li>
        <li className="anti__text">
          Engage with third party community partners to create opportunities for mentorship of young and diverse talent, through apprenticeships or
          leadership training for entrepreneurs, and thereby contribute to building a positive reputation and future for the industry.
        </li>
      </ol>
    </div>
  );
}
export default AntiRacism;
