import React from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import "./Map.scss";
import mapStyle from "./mapStyles";

const libraries = ["places"];

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const center = {
  lat: 43.6532,
  lng: -79.3832,
};

const options = {
  styles: mapStyle,
  zoomControl: true,
  keyboardShortcuts: false,
};

function Map({ selected, setSelected, searchActive, membersList, searchText }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBbMVZGVsI56nplcxQjnnhWNvedq4JG9LY",
    libraries,
  });
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div className="map">
      <GoogleMap options={options} mapContainerStyle={mapContainerStyle} onLoad={onMapLoad} center={center} zoom={9}>
        {searchActive
          ? membersList.map((member) => {
              if (
                member.company.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                member.city.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
              ) {
                return (
                  <Marker
                    key={member.id}
                    position={{ lat: member.lat, lng: member.lng }}
                    onClick={() => {
                      setSelected(member);
                    }}
                  />
                );
              }
              return null;
            })
          : membersList &&
            membersList.map((member, i) => {
              return (
                <Marker
                  key={i}
                  position={{ lat: member.lat, lng: member.lng }}
                  onClick={() => {
                    setSelected(member);
                  }}
                />
              );
            })}

        {selected && (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div className="map__card">
              <h5 className="map__title">{selected.company}</h5>

              <a
                className="map__text"
                href={`https://www.google.com/maps/dir/?api=1&destination=${selected.address.replace(" ", "+")}+${selected.city}`}
              >
                <span className="map__text--bold">Address:</span> {selected.address} {selected.city} {selected.postal}
              </a>

              <p className="map__text">
                <span className="map__text--bold">Phone #:</span> {selected.phone}
              </p>
              {selected.fax && (
                <p className="map__text">
                  <span className="map__text--bold">Fax #:</span> {selected.fax}
                </p>
              )}
              {selected.website && (
                <a className="map__text" href={`http://${selected.website}`}>
                  <span className="map__text--bold">Website: </span> {selected.website}
                </a>
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

export default Map;
