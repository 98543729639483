import React, { useState } from "react";
import "./Members.scss";
import membersList from "../../members.json";
import Map from "../../components/Map/Map";

function Members() {
  const [searchActive, setSearchActive] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const selectedHandler = (selected) => {
    setSelected(selected);
  };

  const changeHandler = (e) => {
    if (!e.target.value) {
      setSearchActive(false);
    } else {
      setSearchActive(true);
      setSearchText(e.target.value);
    }
  };

  return (
    <div id="members" className="members">
      <div className="members__desktop-wrapper">
        <div className="members__container">
          <div className="members__container">
            <div className={`members__category members__category--active`}>
              <h4 className="members__title">MEMBERS LIST</h4>
            </div>
          </div>
          <input className={`members__desktop-search`} placeholder="Search Members" onChange={changeHandler} />
        </div>

        <div className="members__desktop-list">
          {!searchActive &&
            membersList.map((member) => {
              return (
                <div
                  key={member.id}
                  className="members__card"
                  onClick={() => {
                    setSelected(member);
                  }}
                >
                  <p>{member.company}</p>
                </div>
              );
            })}

          {searchActive &&
            membersList.map((member) => {
              if (
                member.company.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                member.city.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
              ) {
                return (
                  <div
                    key={member.id}
                    className="members__card"
                    onClick={() => {
                      setSelected(member);
                    }}
                  >
                    <p>{member.company}</p>
                  </div>
                );
              }
              return null;
            })}
        </div>

        <Map membersList={membersList} selected={selected} setSelected={selectedHandler} searchActive={searchActive} searchText={searchText} />

        <input className={`members__mobile-search`} placeholder="Search Members" onChange={changeHandler} />
      </div>

      <div className="members__mobile-list">
        {!searchActive &&
          membersList.map((member) => {
            return (
              <div
                key={member.id}
                className="members__card"
                onClick={() => {
                  setSelected(member);
                }}
              >
                <p>{member.company}</p>
              </div>
            );
          })}

        {searchActive &&
          membersList.map((member) => {
            if (
              member.company.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
              member.city.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            ) {
              return (
                <div
                  key={member.id}
                  className="members__card"
                  onClick={() => {
                    setSelected(member);
                  }}
                >
                  <p>{member.company}</p>
                </div>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
}

export default Members;
