import React, { useState } from "react";
import "./MobileNavList.scss";
import { Link, useNavigate } from "react-router-dom";
function MobileNavList({ setMenuActive }) {
  let navigate = useNavigate();

  const navigateTo = async (path) => {
    const myPromise = new Promise((resolve, reject) => {
      resolve(navigate(`/#${path}`));
    });
    myPromise.then(() => {
      console.log("first");
      setTimeout(() => {
        document.getElementById(path).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 100);
    });
    setMenuActive(false);
  };

  return (
    <>
      <ul className="mobile-nav-list">
        <li
          className="mobile-nav-list__item"
          onClick={() => {
            navigateTo("hero");
          }}
        >
          <a
            className="mobile-nav-list__link"
            onClick={() => {
              navigateTo("hero");
            }}
          >
            Home
          </a>
        </li>
        <li
          className="mobile-nav-list__item"
          onClick={() => {
            navigateTo("members");
          }}
        >
          <a
            className="mobile-nav-list__link"
            onClick={() => {
              navigateTo("members");
            }}
          >
            Members
          </a>
        </li>
        <li
          className="mobile-nav-list__item"
          onClick={() => {
            navigateTo("board");
          }}
        >
          <a
            className="mobile-nav-list__link"
            onClick={() => {
              navigateTo("board");
            }}
          >
            Board & Directors
          </a>
        </li>

        <li
          className="mobile-nav-list__item"
          onClick={() => {
            navigateTo("contact-form");
          }}
        >
          <a
            className="mobile-nav-list__link"
            onClick={() => {
              navigateTo("contact-form");
            }}
          >
            Contact Us
          </a>
        </li>
      </ul>
    </>
  );
}

export default MobileNavList;
